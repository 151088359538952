<template>
  <v-list>
    <template v-if="auth.user.value!.is_superuser">
      <v-list-subheader title="Admin Area" class="mt-8" /> 
      <v-divider />
      <v-list-item href="/admin/" title="Administration" prepend-icon="mdi-file-document" />
    </template>
  </v-list>
</template>

<script setup lang="ts">
const auth = useAuth();
</script>

<template>
  <s-btn-icon>
    <v-icon icon="mdi-account" />

    <v-menu activator="parent" location="bottom">
      <v-list>
        <v-list-item
          lines="two"
          :title="auth.user.value!.email"
          :subtitle="auth.user.value!.name || ''"
        />
        <v-divider />
        <v-list-item
          to="/users/self/"
          prepend-icon="mdi-account"
          title="Profile"
        />
        <v-list-item
          @click="auth.logout"
          prepend-icon="mdi-logout"
          title="Log out"
          link
        />
      </v-list>
    </v-menu>
  </s-btn-icon>
</template>

<script setup lang="ts">
const auth = useAuth();
</script>

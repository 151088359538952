<template>
  <div class="height-fullscreen">
    <nuxt-loading-indicator :height="2" :color="false" />
    <v-app-bar absolute height="48" elevation="0" color="header" class="menu-appbar">
      <div class="menu-drawer-header border-0">
        <svg-logo-text />
        <span class="badge-text">/{{ props.badgeText }}</span>
      </div>
      <v-spacer />
      <user-info-menu-item v-if="auth.loggedIn.value" />
    </v-app-bar>

    <v-navigation-drawer v-if="auth.loggedIn.value" absolute permanent color="drawer" class="menu-drawer">
      <main-menu class="pt-0 menu-drawer-body" />
    </v-navigation-drawer>

    <v-main class="main-container">
      <slot name="default" />
    </v-main>
    <footer-row />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  badgeText?: string,
}>(), {
  badgeText: 'MGMT',
});

const auth = useAuth();
</script>

<style lang="scss" scoped>
@use 'sass:map';
@use "@/assets/vuetify.scss" as vuetify;

.height-fullscreen {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.nuxt-loading-indicator {
  background: rgb(var(--v-theme-primary));
}

.main-container {
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 0;
  overflow-y: auto;

  & > :deep(.v-main__wrap) {
    height: 100%;
    overflow-y: auto;
  }
}

.menu-appbar {
  font-size: larger;
  z-index: 1 !important;
  margin-top: -1px;
  border-bottom-width: vuetify.$navigation-drawer-border-thin-width;
  border-bottom-style: vuetify.$navigation-drawer-border-style;
  border-bottom-color: vuetify.$navigation-drawer-border-color;
}
.v-app-bar-nav-icon:hover svg {
  fill: rgb(var(--v-theme-primary));
}

.v-breadcrumbs-item {
  .v-icon {
    font-size: 1.5em;
  }
}

.menu-drawer:deep() {
  .v-list-item__spacer {
    width: 1em;
  }
  .v-list-item--active {
    border-left: 0.5em solid rgb(var(--v-theme-primary));
    padding-left: calc(16px - 0.5em);

    .v-list-item__overlay {
      opacity: 0;
    }
    .v-list-item-title {
      font-weight: bold;
    }
  }
  .v-list-item:hover {
    .v-list-item__overlay {
      opacity: calc(#{map.get(vuetify.$states, 'hover')} * var(--v-theme-overlay-multiplier));
    }
  }
  .v-list-item--active, .v-list-item:hover {
    .v-list-item__prepend .v-icon, .v-list-item-title {
      color: rgb(var(--v-theme-primary));
    }
  }
}

.menu-drawer {
  // height: 100% !important;
  // top: 0 !important;
  border-right-width: 0;

  &-header {
    height: 48px;
    background-color: rgb(var(--v-theme-header));
    color: rgb(var(--v-theme-on-header));
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    cursor: pointer;
    border-bottom-width: vuetify.$navigation-drawer-border-thin-width;
    border-bottom-style: vuetify.$navigation-drawer-border-style;
    border-bottom-color: vuetify.$navigation-drawer-border-color;
  }

  &-body {
    border-right-width: vuetify.$navigation-drawer-border-thin-width;
    border-right-style: vuetify.$navigation-drawer-border-style;
    border-right-color: vuetify.$navigation-drawer-border-color;
    flex-grow: 1;
    min-height: 0;
    overflow-y: auto !important;
  }

  &:deep(.v-navigation-drawer__content) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.badge-text {
  height: 30px;
  display: flex;
  flex-direction: column-reverse;
  font-weight: 900;
  font-size: 1.3rem;
  line-height: 1;
  color: rgb(var(--v-theme-logo));
}
</style>
